import React from "react"
import Container from "react-bootstrap/Container"
import Layout from "../components/Layout"
import FAQ from "../components/pricing/FAQ"
import PricingInfo from "../components/pricing/PricingInfo"
import References from "../components/pricing/References"
import SEO from "../components/SEO"

const Pricing = () => (
  <Layout>
    <SEO title="Pricing" />
    <section style={{ backgroundColor: "#f6f8fb" }}>
      <Container className="text-center pt-7 pt-lg-9 pb-9">
        <h1 className="display-3 mb-2">Pricing</h1>
        <p className="font-size-lg text-secondary mb-0">
          Transparent pricing without any hidden fees
        </p>
      </Container>
    </section>
    <PricingInfo />
    <References />
    {/* <FAQ /> */}
  </Layout>
)

export default Pricing
