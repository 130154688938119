import React from "react"
import Icon from "../../Icon"
import { Link } from "gatsby"

const CheckListItem = ({ text }) => (
  <div className="d-flex mb-3">
    <div className="badge badge-rounded-circle badge-primary-soft mt-1 mr-4">
      <Icon icon="check" size="sm" />
    </div>
    <p className="mb-0">{text}</p>
  </div>
)

const PricingInfo = () => {
  const handleClick = () => {
    window.analytics.track("CTA Button Clicked")
  }

  return (
    <section style={{ backgroundColor: "#f6f8fb" }}>
      <div className="container">
        <div className="form-row justify-content-center">
          <div className="col-12 col-md-6">
            <div className="card shadow-lg mb-n14">
              <div className="card-body">
                <div className="row justify-content-center">
                  <div className="col-12 col-xl-9">
                    <div className="d-flex justify-content-center">
                      <span className="price display-4 mb-0">3.5%</span>
                    </div>
                    <p className="text-center mb-5">
                      Platform Fees
                      <br />
                      <br />+ Payment Processing Fees via Stripe
                      <br />
                      <small>
                        <a href="https://stripe.com/pricing">Visit Stripe pricing &#8594;</a>
                      </small>
                    </p>

                    <hr className="my-6" />

                    <div className="mb-n3">
                      <CheckListItem text="No hidden fees" />
                      <CheckListItem text="Unlimited tickets" />
                      <CheckListItem text="Includes all automation tools" />
                      <div className="small text-secondary pl-7 mt-n2 mb-4">
                        <div>- Surveys</div>
                        <div>- Email capture</div>
                        <div>- Ticket sale forecast</div>
                        <div>- Feedback analysis</div>
                      </div>
                      <CheckListItem text="Ambassador program" />
                      <CheckListItem text="Weekly payouts" />
                      <CheckListItem text="135+ Supported Currencies" />
                    </div>
                  </div>
                </div>
              </div>
              <Link
                onClick={handleClick}
                to="start"
                className="card-btn btn btn-block btn-lg btn-primary rounded-bottom"
              >
                Start Selling Tickets
                <Icon icon="arrow-right" size="sm" className="ml-2" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default PricingInfo
