import React from "react"
import Container from "react-bootstrap/Container"
import logo01 from "../../../assets/images/logos/01.png"
import logo02 from "../../../assets/images/logos/02.png"
import logo03 from "../../../assets/images/logos/03.png"
import logo04 from "../../../assets/images/logos/04.png"
import logo05 from "../../../assets/images/logos/05.png"
import logo06 from "../../../assets/images/logos/06.png"
import logo07 from "../../../assets/images/logos/07.png"
import logo08 from "../../../assets/images/logos/08.png"

const References = () => (
  <section class="pt-15 pb-11">
    <Container>
      <div class="row justify-content-center">
        <div class="col-12 col-md-10 col-lg-7 text-center">
          <h2 className="mb-2">Trusted by our customers</h2>
          <p class="font-size-lg text-muted mb-7">
            Companies from all over the world are using Lumis.
          </p>
        </div>
      </div>
      <div class="row">
        <div className="col-3">
          <img src={logo01} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo02} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo03} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo04} class="img-fluid" alt="..." />
        </div>
      </div>
      <div class="row mt-6">
        <div className="col-3">
          <img src={logo05} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo06} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo07} class="img-fluid" alt="..." />
        </div>
        <div className="col-3">
          <img src={logo08} class="img-fluid" alt="..." />
        </div>
      </div>
    </Container>
  </section>
)

export default References
